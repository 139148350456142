import React from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Provider, connect } from "react-redux";
import store from "./store";
import { apiEndpoint } from "./prismic-configuration";
import Index from "./pages/Index";
import ContactUs from "./pages/ContactUs";
import Text from "./pages/Text";
import Catalog from "./pages/Catalog";
import Icon from "./pages/Icon";
import Search from "./pages/Search";
import Preview from "./pages/Preview";
import Privacy_policy from "./pages/Privacy_policy";
import { client } from "./prismic-configuration";
import Prismic from "prismic-javascript";
import { RichText } from "prismic-reactjs";
import htmlSerializer from "./utils/htmlSerializer";
import localLogo from "./assets/images/logo.svg";

import NotFound from "./pages/NotFound";

export default class App extends React.Component {
  state = {
    title: null,
    description: null,
    icon: null,
    logo: null,
  };
  async componentDidMount() {
    const doc = await client.getSingle("site_configuration");
    /*
    
      console.log("doc");
      console.log(doc);
      console.log("site_title");
      console.log(doc.data.site_title);
      console.log("site_description");
      console.log(doc.data.site_description);

    */

    this.setState({
      title: doc.data.site_title,
      description: doc.data.site_description,
      logo: doc.data.website_logo,
      icon: doc.data.website_icon,
    });
  }

  render() {
    const repoNameArray = /([^/]+)\.cdn.prismic\.io\/api/.exec(apiEndpoint);
    const repoName = repoNameArray[1];
    const { title, description, logo, icon } = this.state;

    return (
      <>
        <Helmet>
          <script
            async
            defer
            src={`//static.cdn.prismic.io/prismic.js?repo=${repoName}&new=true`}
          />
          <link
            rel="shortcut icon"
            href={
              icon != null && icon.url != ""
                ? icon.url
                : logo != null && logo.url != ""
                ? logo.url
                : ""
            }
          />
          <title>{title != null ? RichText.asText(title) : ""}</title>

          <meta
            property="og:title"
            content={title != null ? RichText.asText(title) : ""}
          />
          <meta
            property="og:description"
            content={description != null ? RichText.asText(description) : ""}
          />
          <meta
            property="og:image"
            content={logo != null && logo.url != "" ? logo.url : localLogo}
          />
        </Helmet>
        <BrowserRouter>
          <Switch>
            <Route exact path="/preview" component={Preview} />
            <Route exact path="/" component={Index} />
            <Route exact path="/about" component={Text} />
            <Route exact path="/contactus" component={ContactUs} />
            <Route exact path="/privacy_policy" component={Privacy_policy} />
            <Route exact path="/category/:uid" component={Catalog} />
            <Route exact path="/icon/:uid" component={Icon} />
            <Route exact path="/search" component={Search} />
            <Route component={NotFound} />
          </Switch>
        </BrowserRouter>
      </>
    );
  }
}
