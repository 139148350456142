import React from "react";
import { Link } from "react-router-dom";

import "./assets/scss/b-menu.scss";
import { client } from "../../prismic-configuration";
import Prismic from "prismic-javascript";
import { RichText } from "prismic-reactjs";

export default class HeaderMenu extends React.Component {
  constructor(props) {
    super(props);
    this.menuRef = React.createRef();
    this.onClick = this.onClick.bind(this);

    this.state = {
      categories: null,
    };
  }

  onClick() {
    this.menuRef.current.classList.toggle("open");
  }

  componentDidMount() {
    client
      .query([Prismic.Predicates.at("document.type", "site_configuration")])
      .then((response) => {
        response.results.forEach((item) => {
          this.setState({
            categories: item.data.menu,
          });
        });
      });
  }

  render() {
    const categories = [];

    if (this.state.categories) {
      this.state.categories.forEach((category, index) => {
        let item;
        const title = RichText.asText(category.title);
        if (title === "separator") {
          item = <li key={index} className="b-menu__separator"></li>;
        } else {
          item = (
            <li key={index}>
              <Link to={category.link != null ? category.link : ""}>
                {title}
              </Link>
            </li>
          );
        }
        categories.push(item);
      });
    }

    return (
      <div className="b-menu" ref={this.menuRef}>
        <button className="b-menu__button" onClick={this.onClick}>
          <span></span>
          <span></span>
          <span></span>
        </button>
        <ul className="b-menu__list">{categories}</ul>
      </div>
    );
  }
}
