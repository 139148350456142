import React from "react";
import { RichText } from 'prismic-reactjs'
import {client} from "../../../prismic-configuration";
import Prismic from "prismic-javascript";

export default class Sponsor extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sponsorID: this.props.sponsorIcon.id,
            sponsorText: null,
            sponsorImageURL: null,
            sponsorURL: null
        };
    }

    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.sponsorIcon.id !== prevState.sponsorID) {
            return {
                sponsorID: nextProps.sponsorIcon.id
            };
        }
        else return null;
    }

    getSponsors() {
        if(typeof this.state.sponsorID !== "undefined") {
            client.getByID(this.state.sponsorID).then(sponsor => {
                this.setState({
                    sponsorText: sponsor.data.sponsortext,
                    sponsorImageURL: sponsor.data.sponsorlogo.url,
                    sponsorURL: sponsor.data.sponsorurl.url
                });
            });
        } else {
            client.query([
                Prismic.Predicates.at('document.type', 'site_configuration'),
            ]).then(response => {
                response.results.forEach(item => {
                    if(item.data.default_sponsor !== "undefined") {
                        client.getByID(item.data.default_sponsor.id).then(sponsor => {
                            this.setState({
                                sponsorText: sponsor.data.sponsortext,
                                sponsorImageURL: sponsor.data.sponsorlogo.url,
                                sponsorURL: sponsor.data.sponsorurl.url
                            });
                        });
                    }
                });
            });

        }
    }

    componentDidMount() {
        this.getSponsors();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.sponsorID !== this.state.sponsorID) {
            this.getSponsors();
        }
    }

    render() {
        const sponsorText = this.state.sponsorText ? RichText.asText(this.state.sponsorText) : "";

        if(!this.state.sponsorImageURL) {
            return null;
        }

        return (
            <div className="b-iconpage__sponsor">
                <a href={this.state.sponsorURL} target="_blank" rel="noopener noreferrer" className="b-iconpage__sponsor-icon">
                    <img src={this.state.sponsorImageURL} alt=""/>
                </a>
                {sponsorText}
            </div>
        );
    }
}