import React from "react";
import { RichText } from "prismic-reactjs";
import { client } from "../prismic-configuration";
import htmlSerializer from "../utils/htmlSerializer";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
} from "react-share";

import Header from "../components/header/Header";
import Page from "../components/page/Page";
import MainContent from "../components/mainContent/MainContent";
import RelatedIcons from "../components/iconPageComponents/related_icons/RelatedIcons";
import Tags from "../components/iconPageComponents/tags/Tags";
import Categories from "../components/iconPageComponents/categories/Categories";
import Sponsor from "../components/iconPageComponents/sponsor/Sponsor";
import DidYouKnow from "../components/iconPageComponents/didYouKnow/DidYouKnow";
import Stats from "../components/iconPageComponents/iconStats/Stats";
import Footer from "../components/footer/Footer";

import "../assets/scss/b-iconpage.scss";
import Prismic from "prismic-javascript";
import IconThumbnail from "../components/icon_thumbnail/IconThumbnail";

export default class Icon extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      iconUID: this.props.match.params.uid,
      icon: null,
      relatedIconsID: [],
      categoriesID: [],
      nextIconUID: null,
      prevIconUID: null,
      heartIcon: null,
    };

    this.sharedCount = this.sharedCount.bind(this);
    this.onCopy = this.onCopy.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.match.params.uid !== prevState.iconUID) {
      return {
        iconUID: nextProps.match.params.uid,
      };
    } else return null;
  }

  getIcon(UID) {
    client.getByUID("icon", UID).then((icon) => {
      const relatedIconsID = [];
      const categoriesID = [];

      if (icon) {
        icon.data.related_icons.map((related_icon) => {
          if (typeof related_icon.icons.id !== "undefined") {
            relatedIconsID.push(related_icon.icons.id);
          }
          return null;
        });

        icon.data.iconcategory.map((category) => {
          if (typeof category.category.id !== "undefined") {
            categoriesID.push(category.category.id);
          }
          return null;
        });

        this.setState({
          icon: icon,
          relatedIconsID: relatedIconsID,
          categoriesID: categoriesID,
        });

        this.getIconsByCategory(categoriesID, icon.uid);
      } else {
        console.warn("Icon not found");
      }
    });
  }

  getIconsByCategory(categoriesID, currentUID) {
    client
      .query(
        [
          Prismic.Predicates.at("document.type", "icon"),
          Prismic.Predicates.any("my.icon.iconcategory.category", categoriesID),
        ],
        { pageSize: 100 }
      )
      .then((icons) => {
        let prev = null;
        let next = null;
        let currentIndex = null;
        icons.results.forEach((icon, index) => {
          if (icon.uid === currentUID) {
            currentIndex = index;
          }
        });

        if (currentIndex === icons.results.length - 1) {
          prev = icons.results[currentIndex - 1].uid;
        } else if (currentIndex > 0) {
          prev = icons.results[currentIndex - 1].uid;
          next = icons.results[currentIndex + 1].uid;
        } else {
          next = icons.results[currentIndex + 1].uid;
        }

        this.setState({
          nextIconUID: next,
          prevIconUID: prev,
        });
      });
  }

  async componentDidMount() {
    this.getIcon(this.state.iconUID);
    const doc = await client.getSingle("site_configuration");
    console.log(doc);
    this.setState({
      heartIcon: doc.data.heart_symbol_next_to_the_translation_on_icon_page,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.iconUID !== this.state.iconUID) {
      this.getIcon(this.state.iconUID);
    }
  }

  onCopy(text, responce) {
    if (responce) {
      alert("The link was copied!");
      this.sharedCount();
    }
  }

  sharedCount() {
    fetch("/counter.php?page=" + this.state.icon.id + "&shared=1");
  }

  render() {
    const { heartIcon } = this.state;
    const heartIconUrl =
      heartIcon != null && heartIcon.url != "" ? heartIcon.url : "";
    const title = this.state.icon
      ? RichText.asText(this.state.icon.data.iconname)
      : "";
    const description = this.state.icon ? (
      <RichText
        render={this.state.icon.data.icondescription}
        htmlSerializer={htmlSerializer}
      />
    ) : (
      ""
    );
    const translation =
      this.state.icon && this.state.icon.data.translation ? (
        <div
          className="b-iconpage__translation"
          style={
            heartIconUrl != ""
              ? {
                  background:
                    'url("' + heartIconUrl + '") no-repeat left center',
                  paddingLeft: 50,
                  backgroundPosition: "0px",
                }
              : {
                  padding: 0,
                }
          }
        >
          {RichText.asText(this.state.icon.data.translation)}
        </div>
      ) : (
        ""
      );
    let didYouKnow = this.state.icon ? (
      <DidYouKnow render={this.state.icon.data.icondidyouknow} />
    ) : (
      ""
    );
    const iconImage = this.state.icon ? (
      <div className="b-iconpage__icon-wrap">
        <div
          className="b-iconpage__icon"
          style={{
            backgroundImage: `url(${this.state.icon.data.iconimage.url})`,
          }}
        />
      </div>
    ) : (
      ""
    );
    const metaIconImage = this.state.icon
      ? this.state.icon.data.iconimage.url
      : "";
    const relatedIcons = this.state.relatedIconsID.length ? (
      <RelatedIcons iconsID={this.state.relatedIconsID} />
    ) : (
      ""
    );
    const tags = this.state.icon ? <Tags tags={this.state.icon.tags} /> : "";
    const sponsor = this.state.icon ? (
      <Sponsor
        sponsorIcon={this.state.icon.data.iconsponsor}
        sponsorText={this.state.icon.data.sponsortext}
      />
    ) : (
      ""
    );
    const categories = this.state.categoriesID.length ? (
      <Categories categoriesID={this.state.categoriesID} />
    ) : (
      ""
    );
    const shareUrl = window.location.href;

    const prevNav =
      this.state.icon && this.state.prevIconUID ? (
        <Link
          to={`/icon/${this.state.prevIconUID}`}
          className="b-iconpage__nav-prev"
          title="Previous icon"
        />
      ) : (
        ""
      );
    const nextNav =
      this.state.icon && this.state.nextIconUID ? (
        <Link
          to={`/icon/${this.state.nextIconUID}`}
          className="b-iconpage__nav-next"
          title="Next icon"
        />
      ) : (
        ""
      );

    return (
      <>
        <Helmet>
          <title>{title}</title>

          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:image" content={heartIconUrl} />
        </Helmet>
        <Page>
          <Header />
          <MainContent>
            <div className="container">
              <div className="b-iconpage">
                <div className="b-iconpage__nav">
                  {prevNav}
                  {nextNav}
                </div>
                <div className="row">
                  <div className="col-xs-12 col-sm-6 hidden-xs">
                    {iconImage}
                    {relatedIcons}
                  </div>
                  <div className="col-xs-12 col-sm-6">
                    <h1 className="b-iconpage__title">{title}</h1>
                    {translation}
                    <div className="b-iconpage__categories">{categories}</div>
                    {tags}
                    <div className="visible-xs">{iconImage}</div>
                    <div className="b-iconpage__description">{description}</div>
                    <div className="b-iconpage__did-you-know">{didYouKnow}</div>
                    <div className="b-iconpage__share">
                      <span className="b-iconpage__share-text">
                        Share this icon:
                      </span>
                      <CopyToClipboard text={shareUrl} onCopy={this.onCopy}>
                        <span
                          className="b-iconpage__share-ctc"
                          title="Copy URL to clipboard"
                        ></span>
                      </CopyToClipboard>

                      <TwitterShareButton url={shareUrl}>
                        <TwitterIcon
                          iconFillColor="#848d9c"
                          size={40}
                          bgStyle={{ fill: "transparent" }}
                          onClick={this.sharedCount}
                        />
                      </TwitterShareButton>
                      <FacebookShareButton url={shareUrl}>
                        <FacebookIcon
                          iconFillColor="#848d9c"
                          size={40}
                          bgStyle={{ fill: "transparent" }}
                          onClick={this.sharedCount}
                        />
                      </FacebookShareButton>
                    </div>
                    <Stats icon={this.state.icon} />
                    <div
                      className="fb-comments"
                      data-href={shareUrl}
                      data-width="100%"
                      data-numposts="5"
                    ></div>
                    {sponsor}
                    <div className="visible-xs">{relatedIcons}</div>
                  </div>
                </div>
              </div>
            </div>
          </MainContent>
          <Footer />
        </Page>
      </>
    );
  }
}
