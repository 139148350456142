import React from "react";
import Header from "../components/header/Header";
import Page from "../components/page/Page";
import Grid from "../components/grid/Grid";

export default class Main extends React.Component {
  render() {
    return (
      <Page>
        <Header type="transparent" />
        <Grid />

        <a
          href="https://veedoo.io/"
          className="b-page__float-dev-logo"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={require("../assets/images/vedoo_logo.svg")} alt="Veedoo" />
        </a>
      </Page>
    );
  }
}
