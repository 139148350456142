import React from "react";
import SimpleGrid from "../../custom_scripts/grid";
import Prismic from 'prismic-javascript'
import {client} from "../../prismic-configuration";

import './assets/scss/b-icons.scss'

export default class Grid extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            icons: null
        };
    }

    componentDidMount() {
        client.query([
            Prismic.Predicates.at('document.type', 'icon')
        ], {
            pageSize : 100,
            page : 1
        }).then(response => {
            let gallery = {images: []};
            response.results.forEach(function (value, index) {
                gallery.images.push({
                    "id": value.id,
                    "full_src": value.data.iconimage.url,
                    "thumb_src": value.data.iconimage.url,
                    "title": value.data.iconname[0].text,
                    "url": "/icon/"+value.uid+"/",
                    "category": value.data.iconcategory
                });
            });


            let grid = new SimpleGrid( document.getElementById( "grid-container"), gallery );
            grid.init();
        });
    }

    render() {
        return (
            <div className="b-icons">
                <div className="grid-container" id="grid-container"/>
            </div>
        );
    }
}