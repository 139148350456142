import React from "react";
import Page from "../components/page/Page";
import Header from "../components/header/Header";
import MainContent from "../components/mainContent/MainContent";
import Footer from "../components/footer/Footer";
import HubspotForm from "react-hubspot-form";

import { client } from "../prismic-configuration";
import Prismic from "prismic-javascript";
import { RichText } from "prismic-reactjs";
import htmlSerializer from "../utils/htmlSerializer";

export default class Text extends React.Component {
  state = {
    contactusimage: null,
  };

  async componentDidMount() {
    const doc = await client.getSingle("site_configuration");
    /*
    console.log("doc");
    console.log(
      doc
    ); 
          console.log("site_title");
          console.log(doc.data.site_title);
          console.log("site_description");
          console.log(doc.data.site_description);
    
        */

    this.setState({
      contactusimage: doc.data.contactusimage,
    });
  }

  render() {
    const { contactusimage } = this.state;
    const img =
      contactusimage != null && contactusimage.url != ""
        ? contactusimage.url
        : "";
    return (
      <Page>
        <Header />
        <MainContent>
          <div className="container">
            <div className="row row_flex">
              <div className="col-xs-12 col-md-4 hidden-sm hidden-xs">
                <img src={img} alt="" className="b-page__icon" />
              </div>
              <div className="col-xs-12 col-md-8">
                <h1 className="b-page__title">Contact us</h1>
                <iframe src="https://veedoo.fibery.io/@public/forms/ISBrPv70?hide-cover" style={{
                  'width':'100%',
                  'height':'1100px',
                  'padding':0,
                  'border':'none',
                }}/>
              </div>
            </div>
          </div>
        </MainContent>
        <Footer />
      </Page>
    );
  }
}
