import React from "react";
import { Link } from "react-router-dom";

import "./assets/scss/b-header.scss";
import Menu from "../headerMenu/HeaderMenu";

import { client } from "../../prismic-configuration";
import Prismic from "prismic-javascript";
import { RichText } from "prismic-reactjs";
import htmlSerializer from "../../utils/htmlSerializer";

export default class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchQuery: "",
      logo: null,
    };

    this.searchRef = React.createRef();

    this.onChangeSearchQuery = this.onChangeSearchQuery.bind(this);
    this.onSubmitSearchQuery = this.onSubmitSearchQuery.bind(this);
    this.onClickSearchSubmit = this.onClickSearchSubmit.bind(this);
  }
  onChangeSearchQuery(e) {
    const queryValue = e.target.value;

    this.setState({
      searchQuery: queryValue,
    });
  }

  onSubmitSearchQuery(e) {
    if (this.state.searchQuery.length <= 3) {
      e.preventDefault();
    }
  }

  onClickSearchSubmit(e) {
    if (this.state.searchQuery.length <= 3) {
      this.searchRef.current.classList.toggle("opened");
      e.preventDefault();
    }
  }

  async componentDidMount() {
    const doc = await client.getSingle("site_configuration");
    /*
    
      console.log("doc");
      console.log(doc);
      console.log("site_title");
      console.log(doc.data.site_title);
      console.log("site_description");
      console.log(doc.data.site_description);

    */

    this.setState({
      logo: doc.data.website_logo,
    });
  }

  render() {
    const typeClass =
      this.props.type === "transparent" ? "b-header_transparent" : "";
    const { logo } = this.state;

    return (
      <header className={`b-header ${typeClass}`}>
        <div className="b-header__left">
          <Link className="b-header__logo" to="/">
            {logo ? (
              <img
                src={logo != null && logo.url != "" ? logo.url : ""}
                alt="Ukrainian icons"
                style={{
                  height: 120,
                }}
              />
            ) : null}
          </Link>
        </div>
        <div className="b-header__right">
          <div className="b-header__search" ref={this.searchRef}>
            <form
              action="/search"
              method="get"
              onSubmit={this.onSubmitSearchQuery}
            >
              <div className="b-header__search-input-wrap">
                <input
                  type="text"
                  className="b-header__search-input"
                  name="q"
                  onChange={this.onChangeSearchQuery}
                  value={this.state.searchQuery}
                />
              </div>
              <button
                className="b-header__search-submit"
                onClick={this.onClickSearchSubmit}
              />
            </form>
          </div>
          <Menu />
        </div>
      </header>
    );
  }
}
